import { useState } from "react";
import FoodSkeleton from "./FoodSkeleton";
import FoodItem from "./FoodItem";
import useFetch from "../../../hooks/useFetch";
import { Typography } from "@material-tailwind/react";
import { menuItems } from "../../../data/menu";
import "./Main.css";
import { GiHotMeal } from "react-icons/gi";

function Menu({ active }) {
  const [menuTab, setMenuTab] = useState("all");
  const { foods, loading } = useFetch();
  console.log(menuTab);

  const filteredFoods =
    menuTab === "all"
      ? menuItems
      : menuItems.filter((item) => item.category === menuTab);

  const handleMenuTabs = (type) => {
    setMenuTab(type);
  }; 

  return (
    <section id="sectionmenu" className="my-8 max-w-screen-xl mx-auto px-4">
      <div className="flex justify-center items-center mb-6">
        <div className="flex items-center space-x-2">
          <GiHotMeal size={30} />
          <Typography
            variant="h4"
            className="text-[#282828] text-center pt-2"
            style={{ fontSize: "2rem" }}
          >
            MENU
          </Typography>
          <GiHotMeal size={30} />
        </div>
      </div>
      {/* Beginning of filter */}
      <div className="flex flex-wrap items-center justify-center space-x-6">
        {[
          "All",
          "Rice",
          "Fried Rice",
          "Jollof",
          "Chips",
          "Seafood & Protein",
          "Local Favorites",
          "Salad",
          "Others",
        ].map((item) => (
          <p
            key={item}
            className={`my-2 ${
              menuTab === item.toLowerCase()
                ? "active_menu_tab bg-[#B68207]"
                : "menu_tab"
            }`}
            onClick={() => handleMenuTabs(item.toLowerCase())}
          >
            {item}
          </p>
        ))}
      </div>
      {/* End of filter  */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
        {filteredFoods.map(
          (item) =>
            menuItems && (
              <FoodItem
                key={item.id}
                img={item.img}
                more={item.more}
                title={item.name}
                price={item.price}
                category={item.category}
                id={item.id}
                active={active}
              />
            )
        )}
      </div>
    </section>
  );
}

export default Menu;
